import React from 'react';
import { rem } from 'polished';
import * as styles from '../styles';
import {
  Box,
  Clients,
  ContactForm,
  Grid,
  Heading,
  Hero,
  Layout,
  ProductRegisters,
  Projects,
  PurchaseParts,
  Reveals,
  Section,
  Services,
} from '../components';

const IndexPage = () => (
  <Layout>
    <Hero />
    <Section margin="huge" textAlign="center">
      <Grid.Row>
        <Grid.Column>
          <Reveals.FadeBottom>
            <Heading type="h2">ACS provides a wide range of services</Heading>
            <Heading margin="0" type="h4">
              We’ll ensure a great outcome for your building
            </Heading>
          </Reveals.FadeBottom>
        </Grid.Column>
      </Grid.Row>
    </Section>
    <Services />
    <Section margin="huge">
      <Projects />
    </Section>
    <Section margin="huge">
      <Clients />
    </Section>
    <Section backgroundColor={styles.palette.darkGray} padding="large">
      <PurchaseParts />
    </Section>
    <Section margin="xlarge">
      <ProductRegisters />
    </Section>
    <Section
      backgroundColor={styles.palette.darkGray}
      margin="large"
      padding="xlarge"
    >
      <Grid.Row>
        <Grid.Column>
          <Section textAlign="center">
            <Box size="medium">
              <Box size={rem(491)}>
                <Heading type="h2">Drop us a line</Heading>
                <Heading
                  fontFamily={styles.fontStack}
                  margin="0 0 4rem"
                  type="h6"
                >
                  If you have an enquiry or want to know more about us, please
                  use the form below and we&apos;ll be in touch.
                </Heading>
              </Box>
              <ContactForm />
            </Box>
          </Section>
        </Grid.Column>
      </Grid.Row>
    </Section>
  </Layout>
);

export default IndexPage;
